import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { setUserStatus } from '../actions/signInActions'
import { TransHeader } from '../components/header'

class Signin extends React.Component {

	componentDidMount() {
		const res = window.location.href.substring(window.location.href.indexOf("=") + 1);
        if (res === 'failure') { this.setState({ signinStatus: "Social Auth failed" }) }
        const { id, token } = queryString.parse(this.props.location.search);
        this.setState({ id })
        this.setState({ token })
        if (!id || !token) {
            alert("Invalid Link");
            this.props.history.push('/signup');
        }

	}

	state = {
		email: '',
        NewPassword: '',
        ConfirmPassword: '',
        resetStatus: '',
        id: '',
        token: '',
        NewpasswordValid: '',
        ConpasswordValid: '',
		isLoading: false
	}
    formChangeHandler = (e) => {
        console.log(this.state.NewpasswordValid)
		const { name, value } = e.target
		if (name === 'NewPassword') { this.setState({ NewPassword: e.target.value }, () => { this.validateField('NewPassword', value) }) }
		else if (name === 'ConfirmPassword') { this.setState({ ConfirmPassword: e.target.value }, () => { this.validateField('ConfirmPassword', value) }) }
		else { this.setState({ [e.target.name]: e.target.value }) }
	}
      validateField = (fieldName, value) => {
		let { NewpasswordValid, ConpasswordValid } = this.state;

		switch (fieldName) {
			case 'NewPassword':
                NewpasswordValid = value.length >= 6;
				NewpasswordValid = NewpasswordValid ? true : `Password is too short, A minimum of 6 characters is required`;
				break;
			case 'ConfirmPassword':
				ConpasswordValid = this.state.NewPassword === this.state.ConfirmPassword;
				ConpasswordValid = ConpasswordValid ? true : `Provide matching passwords`;
				break;
			default:
				break;
		}
		this.setState({  NewpasswordValid, ConpasswordValid });
    }
    
	loginHandler = (e) => {
		e.preventDefault()
        this.setState({ isLoading: true })
        console.log(this.state)
		axios.post(`${process.env.REACT_APP_API_URL}/cp/reset-password?id=${this.state.id}&token=${this.state.token}`,
			{ password: this.state.ConfirmPassword }
			, {
				headers: {
					'Content-Type': 'application/json; charset=UTF-8'
				}
			})
			.then(response => {
				this.setState({ isLoading: false })
                if (response.status === 200) {
					this.props.history.push('/signin');
				}
                else {
					this.setState({ resetStatus: response.message })
                }
                console.log(response)
			})
			.catch(error => {
				this.setState({ isLoading: false })
				console.log(error)
				error.response !== undefined ?
					this.setState({ resetStatus: error.response.data.message }) : 
					this.setState({ resetStatus: "Network Error" }) ;
			});
	}


	render() {
		return (
			<React.Fragment>
				<div className="signBg align-items-center">
					<div className="bg-transparent pb-5">
						<TransHeader />
					</div>
                    <div className="bg"></div>
					<div className="container">
						<div className="row vcenter">
							<div className="form">
								<div className="card card-signin">
									<div className="card-body">
										<h1 className="card-title text-center wlcm">Reset Password</h1>
										<form className="form-signin" onSubmit={this.loginHandler}>
											<div className="form-label-group mb-4">
												<input type="password" className="form-control" name="NewPassword" placeholder="New Password" required autoFocus
													 onChange={(e) => this.formChangeHandler(e)}
                                                     value={this.state.NewPassword}
                                                />
                                                   	{this.state.NewpasswordValid !== true && this.state.NewpasswordValid !== false ? (
													<small className="form-text pl-4 text-primary">{this.state.NewpasswordValid}</small>
												) : (null)
												}
											</div>

											<div className="form-label-group mb-4">
												<input type="password" id="inputPassword" name="ConfirmPassword" className="form-control" placeholder="Password" required
													 onChange={(e) => this.formChangeHandler(e)}
													value={this.state.ConfirmPassword}
                                                />
                                                	{this.state.ConpasswordValid !== true && this.state.ConpasswordValid !== false ? (
													<small className="form-text pl-4 text-primary">{this.state.ConpasswordValid}</small>
												) : (null)
												}
											</div>


											{this.state.resetStatus !== '' ? (
												<div className="alert alert-primary alert-dismissible fade show" role="alert">
													{this.state.resetStatus}
												</div>
											) : (this.state.resetStatus === 'Reset Success' ? (
												<div className="alert alert-success alert-dismissible fade show" role="alert">
													{this.state.resetStatus}
												</div>
											) : (null))
											}


											<button className="btn btn-lg btn-primary btn-block text-uppercase" type="submit" 	disabled={
													this.state.NewpasswordValid === true &&
														this.state.ConpasswordValid === true
														? false : true}>
												{this.state.isLoading ? (
													<div className="spinner-border spinner-border-sm mr-2" role="status">
														<span className="sr-only">Loading...</span>
													</div>
												) : (null)
												}
												Reset Password
										</button>
											<hr className="my-4" />

										</form>

										{/* <button className="btn btn-lg btn-google btn-block text-uppercase" onClick={() => { window.open(`${process.env.REACT_APP_API_URL}/auth/google`, "_self") }}><i className="fa fa-google mr-2"></i> Sign in with Google</button>
										<button className="btn btn-lg btn-facebook btn-block text-uppercase" onClick={() => { window.open(`${process.env.REACT_APP_API_URL}/auth/facebook`, "_self") }}><i className="fa fa-facebook-f mr-2"></i> Sign in with Facebook</button> */}

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}
Signin.propTypes = {
	setUserStatus: PropTypes.func.isRequired,
};
const mapStateToProps = state => {
	return {
		signIn: state.signIn,
	}
};

export default connect(mapStateToProps, { setUserStatus })(Signin)