import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setUserStatus } from '../../actions/signInActions'


class Signin extends React.Component {

	state = {
		email: '',
		password: '',
		serror: '',
		isLoading: false,
		toggled:false
	}

	handlerChange = (e) => {
		const { id,value } = e.target;
		this.setState({ [id]: value, error:'' });
	}

	loginHandler = (e) => {
		e.preventDefault()
		this.setState({ isLoading: true });
		this.props.setUserStatus('loading', this.state);

		const data = { 
			email: this.state.email, 
			password: this.state.password 
		};

		axios.post(`${process.env.REACT_APP_API_URL}/cp/login`,
			data
			, {
				headers: {
					'Content-Type': 'application/json; charset=UTF-8'
				}
			})
			.then(response => {
				if (response.status === 200) {
					localStorage.setItem('cptoken', response.data.data);
					this.setState({ signinStatus: "Login Success" })
					this.props.history.push('/dashboard');
				}
				else {
					this.setState({ signinStatus: response.message })
				}
			})
			.catch(error => {
				error.response.data !== undefined ?
				this.setState({ error : error.response.data.message }) :
				this.setState({ error: 'Try Again Later' })
			}).finally( e =>
				this.setState({ isLoading: false })
				);
	}

	handlerToggle = ( value ) => {
		this.setState({ toggled : !value})
	}

	render() {
		const { email,password,isLoading,error,toggled } = this.state;
		return (
			<div className="Auth">
				<h1 className="logo">CINETIE</h1>
				<div className="parent">
					<form onSubmit={this.loginHandler}>
						<h1>Welcome back</h1>
						<div className="input-field">
							<input 
								value={email} 
								onChange={this.handlerChange}
								name="email"
								id="email" 
								placeholder="Email"/>
						</div>
						<div className="input-field">
							<input 
								value={password}
								onChange={this.handlerChange}
								id="password"
								name="password"
								type={ toggled ? "text" : "password"} 
								placeholder="Password"
								/>
							{ 
								toggled ? 
								<i onClick={()=> this.handlerToggle(toggled)} className="fas fa-eye-slash"></i>	:
								<i onClick={()=> this.handlerToggle(toggled)} className="fas fa-eye"></i>
							}
						</div>
						<div>
							<Link className="forgot" to="passwordReset">Forgot Password ?</Link>
						</div>
						<p className="error">{ error && <> <span>⚠️</span>  { error } </> }</p>
						<button disabled={ email === "" || password === "" ? true :false }>
							{
								isLoading	?
                        		<i className="spiner fas fa-spinner"></i> :
								<span>Sign In</span>
							}
						</button>
						<p>New To Cinetie ? <Link to="signup">Sign Up Now</Link></p>
					</form>
					<section>
					</section>
				</div>
			</div>
		)
	}
}
Signin.propTypes = {
	setUserStatus: PropTypes.func.isRequired,
};
const mapStateToProps = state => {
	return {
		signIn: state.signIn,
	}
};

export default connect(mapStateToProps, { setUserStatus })(Signin)