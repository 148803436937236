import axios from 'axios';
import { DISPLAY_CP_VIDEOS, DISPLAY_TOTAL_EARNINGS, DISPLAY_ACTIVE_EARNINGS } from './actionType';

export const getCPStats = () => (dispatch) => {
  axios.get(`${process.env.REACT_APP_API_URL}/cp/earnings`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('cptoken')}` }
  })
    .then((res) => {
      dispatch({
        type: DISPLAY_TOTAL_EARNINGS,
        payload: res.data.data.sum
      });
    })
    .catch(err => console.log(err.response))

  axios.get(`${process.env.REACT_APP_API_URL}/cp/earnings/active`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('cptoken')}` }
  })
    .then((res) => {
      dispatch({
        type: DISPLAY_ACTIVE_EARNINGS,
        payload: res.data.data
      });
    })
    .catch(err => console.log(err.response))
};

// export const getVideoStats = () => (dispatch) => {
//   axios.get(`${process.env.REACT_APP_API_URL}/sessions/video?videoId=848f1864-2161-414b-bc5a-06dc9cacf317`, {
//     headers: { Authorization: `Bearer ${localStorage.getItem('cptoken')}` }
//   })
//     .then((res) => {
//       dispatch({
//         type: DISPLAY_VIDEO_STATISTICS,
//         payload: res.data.data
//       });
//     })
//     .catch(err => console.log(err.response))
// };

export const getCPVideos = (type) => (dispatch) => {
  axios.get(`${process.env.REACT_APP_API_URL}/video/sort/type?type=${type}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('cptoken')}` }
  })
    .then((res) => {
      dispatch({
        type: DISPLAY_CP_VIDEOS,
        payload: res.data.data
      });
    })
    .catch(err => {
      console.log(err.response)
      dispatch({
        type: DISPLAY_CP_VIDEOS,
        payload: null
      });
    })
};