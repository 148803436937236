/* eslint-disable no-console */
import { DISPLAY_VIDEO_STATISTICS, DISPLAY_CP_VIDEOS, DISPLAY_TOTAL_EARNINGS, DISPLAY_ACTIVE_EARNINGS } from '../actions/actionType';

const initState = {
  Videos: { movies: [], series: null},
  videoStats: [],
  totalEarnings: '',
  activeEarnings: '',
};

const statisticsReducer = (state = initState, action) => {
  switch (action.type) {
    case DISPLAY_TOTAL_EARNINGS:
      return {
        ...state,
        totalEarnings:  action.payload
      };
    case DISPLAY_ACTIVE_EARNINGS:
      return {
        ...state,
        activeEarnings: action.payload
      };
    case DISPLAY_VIDEO_STATISTICS:
      return {
        ...state,
        videoStats: action.payload,
      };
    case DISPLAY_CP_VIDEOS:
      if(action.payload)
      return {
        ...state,
        Videos: {
          movies: action.payload.filter(el => el.type === "movie"),
          series: action.payload.filter(el => el.type === "serie")
        },
      };
      return{
        ...state,
        Videos: { movies: null, series: null}
      }

    default:
      return state;
  }
};

export default statisticsReducer;
