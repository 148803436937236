import React from 'react'

export const HrThick = () => {
    return (
      <hr className="bg-dark py-1 mb-5" />
    )
  }

export const HrThin = () => {
    return (
      <hr className="bg-dark" />
    )
  }
