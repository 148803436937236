import React, { useEffect, useState } from 'react';
import RandomColor from 'randomcolor';
import { Line } from 'react-chartjs-2';

const CHART = ({ payload }) => {
    const { data } = payload;
    const [ chartData, setCartData ] = useState({});

    useEffect(()=>{
        const labels = ['JAN', 'FEB' ,'MAR','APR','MAY','JUNE', 'JULY', 'AUG' ,'SEPT', 'OCT', 'NOV' ,'DEC'];
        const datasets = [];
        const colors = [];
        for(const [ key ,value ] of Object.entries(data)){
            const numbers = [];
            let AnnuallyIncome;
            let currentColor = RandomColor();

            if(key !== 'sumTotal'){
                for(let i=1;i<=12;i++){
                    const monthlyIncome = value[i] || value[`0${i}`];
                    if(monthlyIncome === undefined){
                        numbers.push(0)
                    }else{
                        numbers.push(parseInt(monthlyIncome.sum))
                    }
                }

                AnnuallyIncome = value['sumYear'];

                for(let b=0;b<=0;b++){
                        const exist = colors.find(el => el === currentColor);
                        if(exist){
                            currentColor = RandomColor({
                                luminosity: 'dark',
                                hue:'lightblue',
                            })
                        }else{
                            colors.push(currentColor)
                            break;
                        }
                }
            }

            if(numbers.length)
                datasets.push({
                    label : `${parseInt(AnnuallyIncome)} ${payload.city ? 'Views': 'Earning'} ${key}` ,
                    data : numbers,
                    backgroundColor:currentColor,
                    borderWidth:4,
                    pointBackgroundColor:'crimson',
                })
        };
        
        setCartData({
            labels,
            datasets,
        });
    },[payload])
 
    return(
        <div>
            <Line data={chartData} options={{
            responsive:true,
            title: { text: payload.city ? 'Annually Views': 'Annually Earning', display :true },
            scales: {
                yAxes: [{
                    ticks:{
                        autoSkip:true,
                        maxTicksLimit:10,
                        beginAtZero:true,
                    },
                    gridLines : {
                        display:false
                    }
                }],
                xAxes:[{
                    gridLines:{
                        display:false
                    }
                }]
            }
        }}/>
        </div>
    )
}

export default CHART
