import { combineReducers } from 'redux';
import signUpReducer from './signUpReducer';
import signInReducer from './signInReducer';
import cpReducer from './cpReducer';
import dataTableReducer from './dataTableReducer';
import barChartReducer from './barChartReducer';

export default combineReducers({
  signUp: signUpReducer,
  signIn: signInReducer,
  cp: cpReducer,
  barChart: barChartReducer,
  dataTable: dataTableReducer
});
