import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import SetAccess from './SetAccess';

import CPSignup from './views/contentProvider/signup'
import TermsAndCond from './views/contentProvider/termsAndCond'
import CPSignin from './views/contentProvider/signin'
import CPForgotPassword from './views/contentProvider/forgotPassword'
import CPNewPassword from './views/NewPassword';
import VerifyEmail from './views/verifyEmail'
import Dashboard from './views/contentProvider/dashboard';
import ErrorNotFound from './views/notfound'

window.addEventListener('pageshow', myFunction);

function myFunction(event) { 
  // if the page was cached, it will remove the cach
  if (event.persisted) {
    window.location.reload(true)
  } 
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <PrivateRoute exact path="/" component={CPSignin} />
          <PrivateRoute exact path="/signup" component={CPSignup} />
          <PrivateRoute exact path="/signin" component={CPSignin} />
          <PrivateRoute exact path="/verifyEmail" component={VerifyEmail} />
          <PrivateRoute exact path="/passwordReset" component={CPForgotPassword} />
          <PrivateRoute exact path="/newPassword" component={CPNewPassword} />
          <PrivateRoute exact path="/cp/access/:token" component={SetAccess} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/conditions" component={TermsAndCond} />

          <Route component={ErrorNotFound} />
              </Switch>
          </Router>
    </Provider>
  );
}

export default App;

