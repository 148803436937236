import React from 'react'

export function Card(props) {
    return (
        <div className={`col-lg-${props.size} col-sm-6`}>
            <div className={`dcard shadow mt-3 ${props.className}`}>
                {props.img ? (<img src={props.img} alt="" className="card-img-top card-img" />) : (null)}
                <div className="card-body" style={{display:"flex",flexFlow:"column",justifyContent:"center",alignItems:"center"}}>
                    <h5 className="card-title">{props.title}</h5>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export function NewCard(props) {
    return (
        <div className="lcard">
            <div className={`dcard shadow mt-3 ${props.className}`}>
                {props.img ? (<img src={props.img} alt="" className="card-img-top card-img" />) : (null)}
                <div className="card-body" style={{display:"flex",flexFlow:"column",justifyContent:"center",alignItems:"center"}}>
                    <h5 className="card-title">{props.title}</h5>
                    {props.children}
                </div>
            </div>
        </div>
    )
}
