/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { withRouter } from "react-router";
import Logo from '../assets/images/logo2.png';

class Header extends Component {

    componentDidMount() {
        if (window.innerWidth < 640) {
            this.setState({ headerColor: 'navbar-dark blackBg' })
        }
        else { window.addEventListener('scroll', this.listenScrollEvent) }
    }

    state = {
        headerColor: 'navbar-dark bg-transparent',
        searchQuery: '',
        logOut: 'Log Out',
    }

    searchHandler = (e) => {
        e.preventDefault()
        this.props.history.push(`/search?searchQuery=${this.state.searchQuery}`);
    }
    listenScrollEvent = e => {
        if (window.scrollY > 80) { this.setState({ headerColor: 'navbar-dark blackBg' }) }
        else { this.setState({ headerColor: 'navbar-dark bg-transparent' }) }
    }

    logOut = () => {
        this.setState({ logOut: 'Loading...' })
        axios.put(`${process.env.REACT_APP_API_URL}/auth/logout`, null, {
            headers: { Authorization: `Bearer ${localStorage.getItem('usertoken')}` }
        })
            .then((res) => {
                this.setState({ logOut: 'Logged Out' })
                localStorage.removeItem('usertoken');
                window.open('/signin', '_self')
            })
            .catch(error => {
                alert('An Error Occured')
                console.log(error.response)
                this.setState({ logOut: 'Log Out' })
            })
    }

    render() {
        return (
            <nav className={`navbar fixed-top navbar-expand-lg ${this.props.theme === 'light' ? ('navbar-dark blackBg') : this.state.headerColor} py-3 font-weight-bold`}>
                {/* <Link className="navbar-brand text-primary font-weight-bold" to="/home">
                    <img src={Logo} alt="" />
                </Link> */}
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/home">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/movies">Movies</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/series">TV Series</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/trending">Trending</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/new">Recently Added</Link>
                        </li>

                    </ul>

                    <ul className="navbar-nav ml-auto pr-2">
                        <li className="nav-item pt-2 pr-2">
                            <form onSubmit={this.searchHandler} className="form-inline my-2 my-lg-0">
                                <div className="row">
                                    <div className="col">
                                        <input value={this.state.searchQuery} onChange={(e) => this.setState({ searchQuery: e.target.value })} name="searchQuery" className="form-control form-control-sm bg-transparent text-white" type="text" placeholder="Search" required />
                                    </div>
                                    <div className="col">
                                        <button className="btn btn-sm btn-primary" type="submit"> <i className="fa fa-search"></i> </button>
                                    </div>
                                    <div className="col">
                                        <li className="nav-item dropdown">
                                            <a className="nav-link pt-0" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="fa fa-user-circle fa-2x"></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                                <Link to="/account"><p className="dropdown-item" href="#">Settings</p></Link>
                                                <div className="dropdown-divider"></div>
                                                <button className="dropdown-item" onClick={() => this.logOut()}>{this.state.logOut}</button>
                                            </div>
                                        </li>
                                    </div>
                                </div>
                            </form>
                        </li>
                    </ul>


                </div>
            </nav>

        )
    }


}

export default withRouter(Header);

export const TransHeader = () => {
    return (<React.Fragment>
        <nav className="navbar pt-4 navbar-dark bg-transparent">
            <Link className="navbar-brand font-weight-bold text-primary" to="/">
                <img src={require('../assets/images/logo2.png')} alt="" />
            </Link>
            <Link className="btn btn-primary my-2 my-sm-0" to="/signin">Sign In</Link>
        </nav>
    </React.Fragment>);
}