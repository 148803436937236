import React from 'react';
import axios from 'axios'

class ForgotPassword extends React.Component {

    state = {
        email: '',
        forgotPasswordStatus: '',
        isLoading: false
    }
    emailChangeHandler = (e) => {
        this.setState({ email: e.target.value })
    }
    forgotPasswordHandler = (e) => {
        e.preventDefault()
        this.setState({ isLoading: true })
        axios.post(`${process.env.REACT_APP_API_URL}/cp/forgot-password`,
            { email: this.state.email }
            , {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                }
            })
            .then(response => {
                this.setState({ isLoading: false })
                console.log(response)
                if (response.status === 200) {
                    console.log(response.message);
                    this.setState({ forgotPasswordStatus: response.status })
                }

            })
            .catch(error => {
                this.setState({ isLoading: false })
                console.log(error.response)
                this.setState({ forgotPasswordStatus: error.response.data.message })
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="hFullSection whiteBg pt-5">
                    <div className="container pt-5">
                        <h2 className="text-primary text-center font-weight-bold">CINETIE</h2>
                        <div className="card col-lg-6 mx-auto mt-5 text-dark shadow">
                            <div className="card-body text-center">
                                <h5 className="card-title pb-2">Content Provider Reset Password</h5>
                                <form className="form-signin" onSubmit={this.forgotPasswordHandler}>
                                    <div className="form-label-group mb-4">
                                        <input type="email" className="form-control" placeholder="Email address" required autoFocus
                                            onChange={this.emailChangeHandler}
                                            value={this.state.email}
                                        />
                                    </div>
                                    {this.state.forgotPasswordStatus === 200 ? (
                                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                                            <h4>Reset password link sent successfully!</h4>
                                        </div>
                                    ) : (this.state.forgotPasswordStatus !== '' ? (
                                        <div className="alert alert-primary alert-dismissible fade show" role="alert">
                                            {this.state.forgotPasswordStatus}
                                        </div>
                                    ) : (null))
                                    }
                                    <button className="btn btn-lg btn-primary btn-block text-uppercase" type="submit">
                                        {this.state.isLoading ? (
                                            <div className="spinner-border spinner-border-sm mr-2" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (null)
                                        }
                                        Submit
									</button>
                                </form>

                            </div>
                        </div>

                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default ForgotPassword