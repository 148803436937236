import axios from 'axios';
import React from 'react';
import MyMovies from './myMovies';
import MySeries from './mySeries';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Btn from '../../components/buttons';
import { Card } from '../../components/card';
import AccountSettings from './accountSettings';
import logo3 from '../../assets/images/logo3.png';
import ModalData from '../../components/modalData';
import avatar from '../../assets/images/avatar.png';
import { getCPStats } from '../../actions/cpActions';
import DataTable from '../../components/dashboard/dataTable';

class Dashboard extends React.Component {

    state = {
        page: 'myMovies',
        rows: [],
        columns: [],
        checkout: null,
        showModal: false,
        showMenu: false,
    }

    handleModalShowClick(e) {
        e.preventDefault();
        this.setState({
            showModal: true
        })
    }

    handleModalCloseClick() {
        this.setState({
            showModal: false
        })
    }

    componentDidMount() {
        this.props.getCPStats()
    }

    selectPage = (page) => {
        this.setState({ page })
    }

    getCheckoutHistory = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/cp/trans`, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}` }
        })
        .then((res) => {
            this.setState({
                rows: res.data.data,
                columns: [{
                    dataField: 'id',
                    text: 'Trans ID',
                    sort: true
                }, {
                    dataField: 'date',
                    text: 'Trans Date',
                    sort: true
                }, {
                    dataField: 'amount',
                    text: 'Amount',
                    sort: true
                },
                {
                    dataField: 'state',
                    text: 'State',
                    sort: true
                }],
                showModal: true
            });
        })
        .catch(err => console.log(err.response))
    };

    render() {


        const checkoutHandler = () => {
            axios.put(`${process.env.REACT_APP_API_URL}/cp/trans/checkout`, null, {
                headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}` }
            })
                .then((res) => {
                    console.log(res.data)
                    this.setState({ checkout: true })
                })
                .catch(err => {
                    console.log(err.response)
                    this.setState({ checkout: false })
                })
        }


        const { page, checkout } = this.state;
        console.log("+++++++++++++++++",this.props.cpStats);
        return (
            <React.Fragment>
                <div className="whiteBg">
                    <nav className="navbar navbar-expand navbar-dark bg-danger static-top">
                        <img src={logo3} alt="" />
                        <div className="ml-auto mr-0 mr-md-3 my-2 my-md-0"></div>

                        <ul className="navbar-nav ml-auto ml-md-0">
                            <li className="nav-item dropdown no-arrow">
                                <Link className="nav-link dropdown-toggle" to="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src={avatar} alt=""/>
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                                    <Link to="#" className="dropdown-item" onClick={() => { localStorage.clear(); window.open('/signin', '_self') }}>Logout</Link>
                                </div>
                            </li>
                        </ul>

                    </nav>

                    <div id="wrapper">

                        { window.screen.width <= 700 && !this.state.showMenu ?
                            <span className="menu">
                                <i className="fas fa-bars" onClick={()=> this.setState({ showMenu:true })}></i>
                            </span>:
                            <ul className="sidebar navbar-nav font-weight-bold">
                                <li className="nav-item  mt-3">
                                    <p onClick={() =>{ 
                                        this.selectPage('myMovies'); 
                                        this.setState({ showMenu:false })
                                        }} className="nav-link">
                                        <i className="fa fa-fw fa-file-video-o"></i>
                                        <span> My Movies</span>
                                    </p>
                                </li>
                                <li className="nav-item  mt-3">
                                    <p onClick={() =>{ 
                                        this.selectPage('mySeries'); 
                                        this.setState({ showMenu:false })
                                        }} className="nav-link">
                                        <i className="fa fa-fw fa-tv"></i>
                                        <span> My Series</span>
                                    </p>
                                </li>

                                <li className="nav-item  mt-3">
                                    <p onClick={() =>{ 
                                        this.selectPage('account'); 
                                        this.setState({ showMenu:false })
                                        }} className="nav-link">
                                        <i className="fa fa-fw fa-user-o"></i>
                                        <span> My Account</span>
                                    </p>
                                </li>
                            </ul>
                        }

                        <div id="content-wrapper">
                            <div className="px-4 pb-3">
                                <div className="row">
                                    <Card size="3" className="text-dark rounded" title="Total Earnings">
                                        <h4>{this.props.cpStats.totalEarnings} USD</h4>
                                    </Card>
                                    <Card size="3" className="text-dark rounded" title="ActiveEarnings">
                                        <h4>{this.props.cpStats.activeEarnings} USD</h4>
                                    </Card>

                                    <div className="col-lg-3 pt-3">
                                        <div className="row">
                                            <Btn style="btn-primary" text="Checkout" onClick={checkoutHandler} />
                                        </div>
                                        <div className="row text-dark">
                                            <Btn onClick={() => { this.getCheckoutHistory() }} style="btn-primary" text="Get Checkout History" />

                                        </div>
                                    </div>
                                    <div className="col-lg-3 pt-3">
                                        {checkout === false ? (
                                            <div className="alert alert-primary alert-dismissible fade show" role="alert">
                                                Can't Checkout The Money.
                                                <button onClick={() => this.setState({ checkout: null })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                        ) : (checkout === true ? (
                                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                Checkout Completed Successfully
                                                <button onClick={() => this.setState({ checkout: null })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                        ) : (null))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid pb-4">

                                {this.state.showModal ? (
                                    <ModalData size="modal-xl" title="Transaction History" handleModalCloseClick={this.handleModalCloseClick.bind(this)} >
                                        <DataTable rows={this.state.rows} columns={this.state.columns} />
                                    </ModalData>) : null}

                                {page === 'myMovies' && <MyMovies />}
                                {page === 'mySeries' && <MySeries />}
                                {page === 'account' && <AccountSettings />}
                            </div>
                            <footer className="sticky-footer">
                                <div className="container my-auto">
                                    <div className="copyright text-center my-auto text-dark">
                                        <span>Copyright © Cinetie 2020</span>
                                    </div>
                                </div>
                            </footer>

                        </div>

                    </div>
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ cp }) => {
    return {
        cpStats: cp,
    }
};
export default connect(mapStateToProps, { getCPStats })(Dashboard)