/* eslint-disable react/style-prop-object */

import axios from 'axios'
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Modal from '../../components/modal';
import { NewCard } from '../../components/card';
import { getCPVideos } from '../../actions/cpActions';
import BarChart from '../../components/dashboard/barChart';
import { Loader } from '../../components/video/videoLoader';

class myVideos extends Component {

    state = {
        videoStatistics: 'views',
        barChartData: 'views',
        videoTrailers: [],
        updateVideoId: '',
        updateStatus: null,
        deleteStatus: null,
        isLoading: false,
        videoName: '',
        videoCast: '',
        videoSynopsis: '',
        category: '',
        file: '',
        uploadTokens: null,
        progressBar: 1,
    }

    currentVideo = {}

    componentDidMount() {
        this.props.getCPVideos('movie')
    }

    getUpdateVideo = (id) => {
        console.log("getting video data")
        this.setState({ updateVideoId: id })
        axios.get(`${process.env.REACT_APP_API_URL}/video/cp/unique?id=${id}`, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}` }
        })
            .then((res) => {
                console.log(res.data.data)
                const { videoName, videoCast, videoSynopsis, category } = res.data.data
                this.setState({ videoName, videoCast, videoSynopsis, category })
            })
            .catch(err => console.log(err.message))
    }

    formChangeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    fileChangeHandler = (e) => {
        const file = e.target.files[0];
        this.setState({ file })
    }
    updateAccount = (e) => {
        e.preventDefault()
        const videoUpdate = {}
        const { videoName, videoCast, videoSynopsis, category } = this.state
        if (videoName !== '' && videoName !== this.currentVideo.videoName) { videoUpdate.videoName = videoName }
        if (videoCast !== '' && videoCast !== this.currentVideo.videoCast) { videoUpdate.videoCast = videoCast }
        if (videoSynopsis !== '' && videoSynopsis !== this.currentVideo.videoSynopsis) { videoUpdate.videoSynopsis = videoSynopsis }
        if (category !== '' && category !== this.currentVideo.category) { videoUpdate.category = category }

        axios.put(`${process.env.REACT_APP_API_URL}/video?id=${this.state.updateVideoId}`, videoUpdate, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}` }
        })
            .then((res) => {
                console.log(res.data)
                this.setState({ updateStatus: true })
            })
            .catch(err => {
                console.log(err.response)
                this.setState({ updateStatus: false })
            })
    }

    getTrailers = (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}/trailer/sort/video?id=${id}`, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}` }
        })
            .then((res) => {
                this.setState({ videoTrailers: res.data.data })
            })
            .catch((err) => console.log(err.response))
    }

    deleteTrailer = (id) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/trailer/delete?id=${id}`, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}` }
        })
            .then((res) => {
                alert(res.data.message)
            })
            .catch((err) => console.log(err.response))
    }

    finishUpload = () => {
        var trailerData = new FormData();
        trailerData.append('videoId', this.state.updateVideoId);
        trailerData.append('trailer', this.state.uploadTokens.Key);

        axios({
            url: `${process.env.REACT_APP_API_URL}/trailer/new`, 
            method: 'POST',
            headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}`, 'Content-Type': 'multipart/form-data' },
            data: trailerData
        })
            .then(response => {
                this.setState({ isLoading: false })
                if (response.status === 201) {
                    alert("Trailer Uploaded Successfully")
                }
                else {
                    this.setState({ uploadStatus: response.message })
                }
            })
            .catch(error => {
                console.log(error)
                this.setState({ isLoading: false })
                alert(error.response.data.message)
            });
    }

    uploadToAWS = async () => {
        const file = this.state.file;
        await axios.put(this.state.uploadTokens.url, file, {
            headers: { 'Content-Type': file.type }, onUploadProgress: progressEvent => {
                let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({ progressBar: progress });
            }
        }).then((res) => {
            console.log(res, `UPLOAD COMPLETED`);
            this.setState({ progressBar: 1 });
            this.finishUpload();
        })
            .catch((err) => {
                this.setState({ isLoading: false })
                alert(err.response);
                console.log(err.response)
            })
    }

    getUploadTokens = () => {
        let extension = 'mp4';
        extension = this.state.file.name.slice((Math.max(0, this.state.file.name.lastIndexOf(".")) || Infinity) + 1);
        axios.get(`${process.env.REACT_APP_API_URL}/trailer/upload-link?extension=${extension}`, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}` }
        })
            .then((res) => {
                this.setState({ uploadTokens: res.data.data })
                this.uploadToAWS();
            })
            .catch((err) => {
                alert(err.response);
                console.log(err.response)
            })
    }

    addTrailer = (e) => {
        e.preventDefault()
        this.setState({ isLoading: true })
        this.getUploadTokens();
    }


    render() {
        const { cpVideos } = this.props;

        return (
            <div>
                <div className="container-fluid text-body">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <span>Content Provider</span>
                        </li>
                        <li className="breadcrumb-item active">My Movies</li>
                    </ol>

                    <h4>Movies</h4>
                    <hr />
                    <div className="parent">
                        {cpVideos === null ? (
                            <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                No Data Present
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        ) : cpVideos.length === 0
                                ? (<Loader />)
                                : (cpVideos.map(video => (
                                    <NewCard size="3" key={video.id} img={video.videoThumbnailLink} title={video.videoName}>
                                        <Modal
                                            style="btn-primary btn-sm" text="Ananlytics"
                                            id={video.videoName.replace(/\s/g, '')}
                                            title={video.videoName}
                                            footer={<p>Total Count : {video.videoWatchCount}</p>}>
                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item">
                                                    <a onClick={() => this.setState({ barChartData: 'views' })} className="nav-link active" id="views-tab" data-toggle="tab" href="#views" role="tab" aria-controls="views" aria-selected="true">Views</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a onClick={() => this.setState({ barChartData: 'earnings' })} className="nav-link" id="earnings-tab" data-toggle="tab" href="#earnings" role="tab" aria-controls="earnings" aria-selected="false">Earnings</a>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="views" role="tabpanel" aria-labelledby="views-tab">
                                                    {this.state.barChartData === 'views' ? (
                                                        <BarChart id={video.videoId} type="views" />
                                                    ) : null}
                                                </div>
                                                <div className="tab-pane fade" id="earnings" role="tabpanel" aria-labelledby="earnings-tab">
                                                    {this.state.barChartData === 'earnings' ? (
                                                        <BarChart id={video.videoId} type="cpVideoEarnings" />
                                                    ) : null}
                                                </div>
                                            </div>
                                        </Modal>
                                    </NewCard>
                                )))}
                    </div>

                </div>
            </div>
        )
    }
}
const mapStateToProps = ({ cp }) => {
    return {
        cpVideos: cp.Videos.movies,
    }
};
export default connect(mapStateToProps, { getCPVideos })(myVideos)
