import React from 'react'

class SetAccess extends React.Component {
  componentDidMount() {
    const { match: { params } } = this.props;
    console.log(params.token);
    if(!params.token) return this.props.history.push('/signin');
    localStorage.setItem('cptoken', params.token);
    return this.props.history.push('/dashboard');
  }
  render() {
    return null
  }
}
export default SetAccess;


