/* eslint-disable react/style-prop-object */
import React from 'react';
import axios from 'axios'
import { HrThin as Hr } from '../../components/hr';
import Btn from '../../components/buttons';
import Modal from '../../components/modal'

class AccountSettings extends React.Component {

    state = {
        accountData: null,
        name: '',
        email: '',
        phoneLine: '',
        nationality: '',
        cityOfResidence: '',
        loading:true,
    }

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_URL}/cp/details`, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}` }
        })
            .then((res) => {
                this.setState({
                    accountData: res.data.data,
                    name: res.data.data.name,
                    email: res.data.data.email,
                    phoneLine: res.data.data.phoneLine,
                    nationality: res.data.data.nationality,
                    cityOfResidence: res.data.data.cityOfResidence,
                    loading:false
                });
            })
            .catch(err => console.log(err.response))
    }

    updateAccount = (e) => {
        e.preventDefault()
        let newData = {}
        const { name, email, phoneLine, nationality, cityOfResidence } = this.state
        if (name !== '' && name !== this.state.accountData.name) { newData.name = name }
        if (email !== '' && email !== this.state.accountData.email) { newData.email = email }
        if (phoneLine !== '' && phoneLine !== this.state.accountData.phoneLine) { newData.phoneLine = phoneLine }
        if (nationality !== '' && nationality !== this.state.accountData.nationality) { newData.nationality = nationality }
        if (cityOfResidence !== '' && cityOfResidence !== this.state.accountData.cityOfResidence) { newData.cityOfResidence = cityOfResidence }
        console.log(newData)

        axios.put(`${process.env.REACT_APP_API_URL}/cp/update`, newData, {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${window.localStorage.getItem('cptoken')}` }
        })
            .then((res) => {
                alert("Account Data Updated SuccessFully !")
            })
            .catch(err => { console.log(err.response); alert(err.message) })
    }

    formChangeHandler = (e) => {
        console.log(e.target.name)
        this.setState({ [e.target.name]: e.target.value })
    }

    formChangeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {
        const deleteAccount = () => {
            axios.delete(`${process.env.REACT_APP_API_URL}/cp/delete_acc`, {
                headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}` }
            })
                .then((res) => {
                    alert(res.data)
                    window.localStorage.clear()
                })
                .catch(err => {
                    console.log(err.response)
                    alert(err.message)
                })
        }

        const { name, phoneLine, email, nationality, cityOfResidence, loading } = this.state
        return (
            <React.Fragment>

                <div className="whiteBg text-dark pb-5">
                    <div className="container-fuid">
                        <div className="account">
                            <Hr />
                            <div className="row">
                                <div className="col-lg-3 mb-3">
                                    <h5 className="text-muted">
                                        ACCOUNT DETAILS
                                    </h5>

                                </div>
                                <div className="col-lg-9">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <form className="pb-4" onSubmit={this.updateAccount}>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <h6 className="text-muted">Names</h6>
                                                            <input type="text" disabled={ loading ? true : false } className="form-control" name="name" onChange={this.formChangeHandler} value={name} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <h6 className="text-muted">Email</h6>
                                                            <input type="email" disabled={ loading ? true : false } className="form-control" name="email" onChange={this.formChangeHandler} value={email} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <h6 className="text-muted">Phone</h6>
                                                            <input type="number" disabled={ loading ? true : false } className="form-control" name="phoneLine" onChange={this.formChangeHandler} value={phoneLine} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <h6 className="text-muted">Nationality</h6>
                                                            <input type="text" disabled={ loading ? true : false } className="form-control" name="nationality" onChange={this.formChangeHandler} value={nationality} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <h6 className="text-muted">City of Residence</h6>
                                                            <input type="text" disabled={ loading ? true : false } className="form-control" name="cityOfResidence" onChange={this.formChangeHandler} value={cityOfResidence} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button className="btn btn-primary mr-2" type="submit">
                                                    <i style={{margin:'0 5px'}} className="fas fa-sync-alt"></i> Update Account
                                                </button>

                                            </form>


                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default AccountSettings