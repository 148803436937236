import React from 'react'
import queryString from 'query-string';

export default class termsAndCond extends React.Component {

    state = {
        type: 'user'
    }

    componentDidMount() {
        const params = queryString.parse(this.props.location.search);
        if (params.type === '') window.location.href = `/home`;
        else {
            this.setState({ type: params.type })
        }
    }

    render() {
        return (
            <>
                <div className="whiteBg py-5 hFullSection">
                    <div className="container p4-5">
                        <h2 className="text-primary font-weight-bold text-center">CINETIE</h2>
                        <div className="card col-lg-12 mx-auto mt-5 text-dark shadow">
                            <div className="card-body">
                                <h5 className="card-title pb-2 text-center">Terms And Conditions</h5>
                                <h6 className="text-muted text-center">For {this.state.type === 'user' ? 'Users' : 'Content Providers'}</h6>
                                <hr />
                                {this.state.type === 'user' ? (
                                    <div>
                                        <p><strong>CINETIE Terms &nbsp;and conditions of Use</strong></p>
                                        <p><strong>Updated June 2020</strong></p>
                                        <p>Welcome to the Live Streaming services offered by the cinetie. The following terms and conditions (the &ldquo;Terms&rdquo;) govern your access to and/or use of the Live Streaming platform, website and KFTV&rsquo;s content, services and movies available at and/or through the Cinetie, including, but not limited to, streaming&nbsp; of films, online uploads, display, delivery, acknowledgment, and limited storage services for documents and/or electronic contracts (collectively, the &ldquo;Services&rdquo;).</p>
                                        <p><strong>ARBITRATION NOTICE</strong></p>
                                        <p><strong>ARBITRATION NOTICE</strong>: EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION CLAUSE SET FORTH HEREIN, YOU AGREE THAT DISPUTES BETWEEN YOU AND CINETIE WILL BE RESOLVED BY MANDATORY ARBITRATION AND YOU WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS-ACTION LAWSUIT AND/OR CLASS-WIDE ARBITRATION.</p>
                                        <ol>
                                            <li><strong> ACCEPTANCE OF TERMS</strong></li>
                                        </ol>
                                        <p>The Services are offered subject to your acceptance of the terms and conditions contained herein and all other operating rules, policies (including, without limitation, Cinetie&rsquo;s Privacy Policy) and procedures governing the platform.&nbsp; By accessing, registering for and/or using the Services in any manner you agree to the Terms. If you do not agree to the Terms, we recommend you to do not use the Services provided by Cinetie.&nbsp;</p>
                                        <ol start="2">
                                            <li><strong> CHANGES</strong></li>
                                        </ol>
                                        <p>We may make changes to the Terms and conditions/or privacy services, &nbsp;our other operating rules, policies and/or procedures from time to time, in our sole and absolute discretion.&nbsp; We will notify you of any changes to the Terms by posting the updated Terms on Cinetie&rsquo;s website and/or the Services, and we will revise the &ldquo;Updated&rdquo; date above.&nbsp; Any changes to our operating rules, policies and/or procedures shall be incorporated herein. It is your responsibility to review the Terms frequently and to remain informed of any changes to them.&nbsp; We may also provide you with additional forms of notice of modifications and/or updates as appropriate under the circumstances including but not limited to sending you an email informing you of the changes and/or by prominently posting notice of the changes on Cinetie&rsquo;s website.&nbsp; If you do not agree with the changes and/or modifications, you shall not use the Services after the effective date of the changes. The then-current version of the Terms will supersede all earlier versions. You agree that your continued use of the Services after such changes have been published will constitute your acceptance of such revised Terms and operating rules, policies and/or procedures (as applicable).</p>
                                        <ol start="3">
                                            <li><strong>LICENSE TO USE OUR SERVICES</strong></li>
                                        </ol>
                                        <p><strong>3.1. Ownership and Rights to Access and/or Use the Services</strong>.&nbsp; By using the Services and subject to your compliance with the Terms: (i) Cinetie and our licensors grant to you a limited, personal, non-exclusive, non-transferable, revocable right to use and/or access the Services; and (ii) you agree not to reproduce, distribute, create derivative works from, publicly display, publicly perform, license, sell and/or re-sell any content obtained from and/or through the Services without the express prior written permission of Cinetie.&nbsp; Neither the Terms nor your access to and/or use of the Services transfers to you and/or any third party any rights, title and/or interest in any intellectual property rights related to the Services.&nbsp;</p>
                                        <p>3.2. <strong>Reservation of Rights.</strong>&nbsp; Our Services and those of our licensors are protected by applicable intellectual property laws, including Rwanda copyright law and international treaties.&nbsp; Subject to the limited rights expressly granted hereunder, Cinetie and/or its third-party providers, licensors, and suppliers reserve all right, title and interest in and to the Services, including without limitation all related worldwide intellectual property rights.&nbsp; No rights are granted to you hereunder other than as expressly set forth herein.</p>
                                        <p>&nbsp;</p>
                                        <p>3.3. <strong>Availability of Content.</strong>&nbsp; We do not guarantee that any content will be made available on the platform.&nbsp; We reserve the right to, but do not have any obligation to, (i) remove, edit and/or modify any content in our sole discretion, at any time, without notice to you and for any reason (including, but not limited to, upon receipt of claims and/or allegations from third parties and/or authorities relating to such content and/or if we are concerned that you may have violated the Terms), and/or for no reason at all and (ii) remove or block any content from the platform.</p>
                                        <ol start="4">
                                            <li><strong>SCOPE OF USE</strong></li>
                                        </ol>
                                        <p>4.1. <strong>Use of Services and Availability.</strong>&nbsp; Cinetie retains the right, in its sole and absolute discretion, to deny access to and/or use of the Services to anyone at any time and for any reason. You are solely responsible for ensuring that your access to and/or use of the platform is in compliance with all laws, rules and regulations applicable to you and the right to access and/or use the Services is revoked where your access to and/or use of the Services is prohibited. Further, the Services are offered only for your use, and also for the use and/or benefit of any third party depending on your subscription package.</p>
                                        <p><strong>4.2. Creating an Account</strong>.&nbsp; To sign up, access and/or use the platform, you must create an account (an &ldquo;Account&rdquo;). Your Account requires you to (i) indicate agreement to the Terms, (ii) provide contact information, and (iii) submit any other form of authentication required during the registration process, as determined by Cinetie in its sole and absolute discretion. You agree not to create an Account and/or use the platform if you have been previously removed by us and/or banned from the platform (this is likely to happen, but in case it does, action will be taken by Cinetie). You agree that Cinetie will not be liable to you and/or to any third party for any suspension and/or termination of your Account and/or any refusal of any access to and/or use of the Services (or any portion thereof).&nbsp; You must provide accurate and complete information and keep your Account information updated. You shall not: (a) select and/or use as a username a name of another person with the intent to impersonate that person; (b) use as a username a name subject to any rights of a person other than you without appropriate authorization; and/or (c) use as a username a name that is otherwise offensive, vulgar and/or obscene.&nbsp; You are solely responsible for maintaining the security of your Account, and you are fully responsible for all activities that occur under your Account and any other actions taken in connection with your Account. Depending on your subscription package, your Account is non-transferrable and may not be sold, combined and/or otherwise shared with any other person.</p>
                                        <ol start="5">
                                            <li><strong> PAYMENT</strong></li>
                                        </ol>
                                        <p>You can subscribe to a monthly plan depending on your preference. You can use your credit card to subscribe through our secure payment gateway. All fees, expenses and taxes due hereunder will be paid in U.S. dollars or Rwandan Franc.&nbsp; All fees due and payable by you to Cinetie under the Terms must be paid in full without any deduction, set-off, counterclaim or withholding of any kind unless required by law. All fees due and payable under the Terms are exclusive of taxes, which will be added at the prevailing rate from time to time.&nbsp; When you sign up for a paid plan, you are agreeing to pay and take responsibility for all charges made in accordance with the chosen plan, and your subscription will automatically renew at the end of the subscription term unless you cancel as further set forth herein.</p>
                                        <p>5.1. <strong>Cancellation policy.</strong>&nbsp; Once a user submits a request for cancellation, no additional charges will be made. However, no refunds (prorated or otherwise) are provided upon cancellation. In the interest of fairness to all of our clients, no exceptions will be made.</p>
                                        <p>5.2. <strong>Free 05-day trial</strong>.&nbsp; Cinetie offers a free 05-day trial for all of our plans.&nbsp; You will be required to enter payment information, but your plan will expire at the end of the 05 day. Your first charge will occur at the end of the 05th day after your signup date. If you elect the 05 day trial and you cancel your Account before the end of the 05th day, you won&rsquo;t be billed at all.</p>
                                        <p>5.3. <strong>Monthly plan billing.</strong>&nbsp; Cinetie&rsquo;s monthly plans provide month-to-month access, with monthly charges being made each renewal day (the same day of the month that you originally signed up for the plan). Monthly plans automatically renew every month.&nbsp; If you cancel before an upcoming renewal day, you will have access to your Account through the end of the then-current month.</p>
                                        <p><strong>5.5. Failed charges; Suspension.</strong>&nbsp; If your credit card on file is closed or the Account information is changed, or if, for any reason, a charge is rejected, you shall immediately update your Account or supply a new payment account, as appropriate.&nbsp; If you are unable to update your credit card account with appropriate information, then Cinetie will send an invoice to you detailing the amount due. You must pay the amount due in full within seven (30) days after the date of the invoice.&nbsp; You agree to notify Cinetie in writing of any changes to your Account information or termination of any authorization at least thirty (30) days prior to the immediately subsequent billing date. &nbsp;In the event of the foregoing, Cinetie shall not be obligated to provide any and/or all of the Services until such fees are paid in full.&nbsp; Accounts that have been terminated may be reactivated if valid payment information is entered and the card can be successfully processed for all charges accrued on the Account since the failed credit card charge.</p>
                                        <ol start="6">
                                            <li><strong> USER CONTENT</strong></li>
                                        </ol>
                                        <p>6.1. You represent and warrant that: (i) any information and/or User Content you provide in connection with your access to and/or use of the Services is true, accurate and complete and you will maintain and update such information regularly; and (ii) you will respect the intellectual property, other information and all rights of Cinetie and others using the Services.</p>
                                        <p><strong>6.2. Feedback.</strong>&nbsp; Please keep in mind that we do not seek any unsolicited ideas and/or materials for the Services.&nbsp; If you provide us (in a direct mail, email and/or otherwise) with any feedback, suggestions, improvements, enhancements, recommendations and/or feature requests relating to the Services (&ldquo;Feedback&rdquo;), then you further grant to Cinetie a worldwide, perpetual, irrevocable, royalty-free license to use, reproduce, perform, display, distribute, adapt, modify, re-format, create derivative works of, and otherwise commercially or non-commercially exploit in any manner it deems appropriate, any and all Feedback, and to sublicense the foregoing rights. Cinetie will treat any Feedback you provide to Cinetie as non-confidential and non-proprietary. You represent and warrant that you have all rights necessary to submit the Feedback.</p>
                                        <p>6.3. <strong>Content Provided by Others</strong>.&nbsp; Our platform may contain User (movie producers) Content provided by other users and/or third parties.&nbsp; Please carefully choose the information that you post on, through and/or in connection with the Services. It is your responsibility to ensure that Services users to whom you submit personally identifiable or confidential information will take appropriate security and non-disclosure measures.</p>
                                        <ol start="7">
                                            <li><strong>REVIEW OF USER CONTENT BY CINETIE</strong></li>
                                        </ol>
                                        <p><strong>7.1. Enforcement. </strong>Cinetie does will undertake to screen, review, edit, censor and/or otherwise filter and/or control producer Content for our platform. We reserve the right to review any User Content, and to investigate and/or take appropriate action against you in our sole discretion if you violate the Terms and/or otherwise create liability for us or any other person. Such action may include without limitation removing and/or modifying your producer content. We do not allow anyone to record the screen on our platform .</p>
                                        <ol start="8">
                                            <li><strong> NO LEGAL ADVICE; ELECTRONIC COMMUNICATION</strong></li>
                                        </ol>
                                        <p>8.1. No legal advice.&nbsp; The platform does not provide legal advice and cinetie is not a law firm.&nbsp; Part of the Services may involve the making of contracts, and/or other legal relations and although we attempt to make sure our information is accurate and useful, we recommend you consult with a lawyer if you want legal advice.&nbsp; Cinetie does not offer any legal advice, legal opinions, recommendations, referrals, and/or counseling. Cinetie is not involved in agreements between you and other users.</p>
                                        <p><strong>8.2. Local Laws.</strong>&nbsp; The use of the Services may be governed by the laws of different countries or regions, and you agree to abide by such local laws.</p>
                                        <p>8.3. <strong>Electronic Communications.</strong>&nbsp; By using the Services, you agree to receive certain communications in connection with the Services.&nbsp; For contractual purposes, you (i) consent to receive communications from Cinetie in an electronic form; and (ii) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Cinetie provides to you electronically satisfy any legal requirement that such communications would satisfy if it were to be a hardcopy in writing.&nbsp; The foregoing does not affect your non-waivable rights.&nbsp;</p>
                                        <ol start="9">
                                            <li><strong> TERM; TERMINATION</strong></li>
                                        </ol>
                                        <p>Subject to this Section, the Terms will remain in full force and effect while you access and/or use the platform. If you wish to terminate your Account, you may do so by following the instructions through your Account. Cinetie will not have any liability whatsoever to you for any termination of your rights under the Terms, including without limitation for termination of your Account and/or deletion of your User Content. &nbsp;</p>
                                        <ol start="10">
                                            <li><strong> DISCLAIMER OF LIABILITY</strong></li>
                                        </ol>
                                        <p>11.1. Cinetie is under no obligation to become involved in any dispute that you have with other users and/or in any incident that you are party to with other users, or that are affected by and/or otherwise related to the Services.</p>
                                        <p><strong>11.2. Governing Law.</strong>&nbsp; Except to the extent applicable law, if any, provides otherwise, this Agreement and any access to or use of the Services will be governed by the laws of the Republic of Rwanda.</p>
                                        <p><strong>11.3. Reference</strong>. You agree that we may reference you as our customer, and that we may reasonably use, on a royalty-free basis, your trademark and/or logo for such purpose.</p>
                                        <ol start="12">
                                            <li><strong> CONTACT</strong></li>
                                        </ol>
                                        <p>If you would like to request additional information regarding the Terms or for any questions regarding a commercial relationship with Cinetie, please contact us at <a href="mailto:legal@cinetie.com">legal@cinetie.com</a></p>

                                        <div className="text-center">
                                            <button onClick={() => { this.props.history.push('/signup') }} className="btn btn-primary">Get Me To Sign Up Page</button>
                                        </div>
                                    </div>
                                ) : (
                                        <div>



                                            <p><strong>CINETIE Terms &nbsp;and conditions of Use</strong></p>
                                            <p><strong>Updated June 2020</strong></p>
                                            <p>Welcome to the Live Streaming services offered by the cinetie. The following terms and conditions (the &ldquo;Terms&rdquo;) govern your access to and/or use of the Live Streaming platform, website and KFTV&rsquo;s content, services and movies available at and/or through the Cinetie, including, but not limited to, streaming&nbsp; of films, online uploads, display, delivery, acknowledgment, and limited storage services for documents and/or electronic contracts (collectively, the &ldquo;Services&rdquo;).</p>
                                            <p><strong>ARBITRATION NOTICE</strong></p>
                                            <p><strong>ARBITRATION NOTICE</strong>: EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION CLAUSE SET FORTH HEREIN, YOU AGREE THAT DISPUTES BETWEEN YOU AND CINETIE WILL BE RESOLVED BY MANDATORY ARBITRATION AND YOU WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS-ACTION LAWSUIT AND/OR CLASS-WIDE ARBITRATION.</p>
                                            <ol>
                                                <li><strong> ACCEPTANCE OF TERMS</strong></li>
                                            </ol>
                                            <p>The Services are offered subject to your acceptance of the terms and conditions contained herein and all other operating rules, policies (including, without limitation, Cinetie&rsquo;s Privacy Policy) and procedures governing the platform.&nbsp; By accessing, registering for and/or using the Services in any manner you agree to the Terms. If you do not agree to the Terms, we recommend you to do not use the Services provided by Cinetie.&nbsp;</p>
                                            <ol start="2">
                                                <li><strong> CHANGES</strong></li>
                                            </ol>
                                            <p>We may make changes to the Terms and conditions/or privacy services, &nbsp;our other operating rules, policies and/or procedures from time to time, in our sole and absolute discretion.&nbsp; We will notify you of any changes to the Terms by posting the updated Terms on Cinetie&rsquo;s website and/or the Services, and we will revise the &ldquo;Updated&rdquo; date above.&nbsp; Any changes to our operating rules, policies and/or procedures shall be incorporated herein. It is your responsibility to review the Terms frequently and to remain informed of any changes to them.&nbsp; We may also provide you with additional forms of notice of modifications and/or updates as appropriate under the circumstances including but not limited to sending you an email informing you of the changes and/or by prominently posting notice of the changes on Cinetie&rsquo;s website.&nbsp; If you do not agree with the changes and/or modifications, you shall not use the Services after the effective date of the changes. The then-current version of the Terms will supersede all earlier versions. You agree that your continued use of the Services after such changes have been published will constitute your acceptance of such revised Terms and operating rules, policies and/or procedures (as applicable).</p>
                                            <ol start="3">
                                                <li><strong>LICENSE TO USE OUR SERVICES</strong></li>
                                            </ol>
                                            <p><strong>3.1. Ownership and Rights to Access and/or Use the Services</strong>.&nbsp; By using the Services and subject to your compliance with the Terms: (i) Cinetie and our licensors grant to you a limited, personal, non-exclusive, non-transferable, revocable right to use and/or access the Services; and (ii) you agree not to reproduce, distribute, create derivative works from, publicly display, publicly perform, license, sell and/or re-sell any content obtained from and/or through the Services without the express prior written permission of Cinetie.&nbsp; Neither the Terms nor your access to and/or use of the Services transfers to you and/or any third party any rights, title and/or interest in any intellectual property rights related to the Services.&nbsp;</p>
                                            <p>3.2. <strong>Reservation of Rights.</strong>&nbsp; Our Services and those of our licensors are protected by applicable intellectual property laws, including Rwanda copyright law and international treaties.&nbsp; Subject to the limited rights expressly granted hereunder, Cinetie and/or its third-party providers, licensors, and suppliers reserve all right, title and interest in and to the Services, including without limitation all related worldwide intellectual property rights.&nbsp; No rights are granted to you hereunder other than as expressly set forth herein.</p>
                                            <p>&nbsp;</p>
                                            <p>3.3. <strong>Availability of Content.</strong>&nbsp; We do not guarantee that any content will be made available on the platform.&nbsp; We reserve the right to, but do not have any obligation to, (i) remove, edit and/or modify any content in our sole discretion, at any time, without notice to you and for any reason (including, but not limited to, upon receipt of claims and/or allegations from third parties and/or authorities relating to such content and/or if we are concerned that you may have violated the Terms), and/or for no reason at all and (ii) remove or block any content from the platform.</p>
                                            <ol start="4">
                                                <li><strong>SCOPE OF USE</strong></li>
                                            </ol>
                                            <p>4.1. <strong>Use of Services and Availability.</strong>&nbsp; Cinetie retains the right, in its sole and absolute discretion, to deny access to and/or use of the Services to anyone at any time and for any reason. You are solely responsible for ensuring that your access to and/or use of the platform is in compliance with all laws, rules and regulations applicable to you and the right to access and/or use the Services is revoked where your access to and/or use of the Services is prohibited. Further, the Services are offered only for your use, and also for the use and/or benefit of any third party depending on your subscription package.</p>
                                            <p><strong>4.2. Creating an Account</strong>.&nbsp; To sign up, access and/or use the platform, you must create an account (an &ldquo;Account&rdquo;). Your Account requires you to (i) indicate agreement to the Terms, (ii) provide contact information, and (iii) submit any other form of authentication required during the registration process, as determined by Cinetie in its sole and absolute discretion. You agree not to create an Account and/or use the platform if you have been previously removed by us and/or banned from the platform (this is likely to happen, but in case it does, action will be taken by Cinetie). You agree that Cinetie will not be liable to you and/or to any third party for any suspension and/or termination of your Account and/or any refusal of any access to and/or use of the Services (or any portion thereof).&nbsp; You must provide accurate and complete information and keep your Account information updated. You shall not: (a) select and/or use as a username a name of another person with the intent to impersonate that person; (b) use as a username a name subject to any rights of a person other than you without appropriate authorization; and/or (c) use as a username a name that is otherwise offensive, vulgar and/or obscene.&nbsp; You are solely responsible for maintaining the security of your Account, and you are fully responsible for all activities that occur under your Account and any other actions taken in connection with your Account. Depending on your subscription package, your Account is non-transferrable and may not be sold, combined and/or otherwise shared with any other person.</p>
                                            <ol start="5">
                                                <li><strong> PAYMENT</strong></li>
                                            </ol>
                                            <p>You can subscribe to a monthly plan depending on your preference. You can use your credit card to subscribe through our secure payment gateway. All fees, expenses and taxes due hereunder will be paid in U.S. dollars or Rwandan Franc.&nbsp; All fees due and payable by you to Cinetie under the Terms must be paid in full without any deduction, set-off, counterclaim or withholding of any kind unless required by law. All fees due and payable under the Terms are exclusive of taxes, which will be added at the prevailing rate from time to time.&nbsp; When you sign up for a paid plan, you are agreeing to pay and take responsibility for all charges made in accordance with the chosen plan, and your subscription will automatically renew at the end of the subscription term unless you cancel as further set forth herein.</p>
                                            <p>5.1. <strong>Cancellation policy.</strong>&nbsp; Once a user submits a request for cancellation, no additional charges will be made. However, no refunds (prorated or otherwise) are provided upon cancellation. In the interest of fairness to all of our clients, no exceptions will be made.</p>
                                            <p>5.2. <strong>Free 05-day trial</strong>.&nbsp; Cinetie offers a free 05-day trial for all of our plans.&nbsp; You will be required to enter payment information, but your plan will expire at the end of the 05 day. Your first charge will occur at the end of the 05th day after your signup date. If you elect the 05 day trial and you cancel your Account before the end of the 05th day, you won&rsquo;t be billed at all.</p>
                                            <p>5.3. <strong>Monthly plan billing.</strong>&nbsp; Cinetie&rsquo;s monthly plans provide month-to-month access, with monthly charges being made each renewal day (the same day of the month that you originally signed up for the plan). Monthly plans automatically renew every month.&nbsp; If you cancel before an upcoming renewal day, you will have access to your Account through the end of the then-current month.</p>
                                            <p><strong>5.5. Failed charges; Suspension.</strong>&nbsp; If your credit card on file is closed or the Account information is changed, or if, for any reason, a charge is rejected, you shall immediately update your Account or supply a new payment account, as appropriate.&nbsp; If you are unable to update your credit card account with appropriate information, then Cinetie will send an invoice to you detailing the amount due. You must pay the amount due in full within seven (30) days after the date of the invoice.&nbsp; You agree to notify Cinetie in writing of any changes to your Account information or termination of any authorization at least thirty (30) days prior to the immediately subsequent billing date. &nbsp;In the event of the foregoing, Cinetie shall not be obligated to provide any and/or all of the Services until such fees are paid in full.&nbsp; Accounts that have been terminated may be reactivated if valid payment information is entered and the card can be successfully processed for all charges accrued on the Account since the failed credit card charge.</p>
                                            <ol start="6">
                                                <li><strong> USER CONTENT</strong></li>
                                            </ol>
                                            <p>6.1. You represent and warrant that: (i) any information and/or User Content you provide in connection with your access to and/or use of the Services is true, accurate and complete and you will maintain and update such information regularly; and (ii) you will respect the intellectual property, other information and all rights of Cinetie and others using the Services.</p>
                                            <p><strong>6.2. Feedback.</strong>&nbsp; Please keep in mind that we do not seek any unsolicited ideas and/or materials for the Services.&nbsp; If you provide us (in a direct mail, email and/or otherwise) with any feedback, suggestions, improvements, enhancements, recommendations and/or feature requests relating to the Services (&ldquo;Feedback&rdquo;), then you further grant to Cinetie a worldwide, perpetual, irrevocable, royalty-free license to use, reproduce, perform, display, distribute, adapt, modify, re-format, create derivative works of, and otherwise commercially or non-commercially exploit in any manner it deems appropriate, any and all Feedback, and to sublicense the foregoing rights. Cinetie will treat any Feedback you provide to Cinetie as non-confidential and non-proprietary. You represent and warrant that you have all rights necessary to submit the Feedback.</p>
                                            <p>6.3. <strong>Content Provided by Others</strong>.&nbsp; Our platform may contain User (movie producers) Content provided by other users and/or third parties.&nbsp; Please carefully choose the information that you post on, through and/or in connection with the Services. It is your responsibility to ensure that Services users to whom you submit personally identifiable or confidential information will take appropriate security and non-disclosure measures.</p>
                                            <ol start="7">
                                                <li><strong>REVIEW OF USER CONTENT BY CINETIE</strong></li>
                                            </ol>
                                            <p><strong>7.1. Enforcement. </strong>Cinetie does will undertake to screen, review, edit, censor and/or otherwise filter and/or control producer Content for our platform. We reserve the right to review any User Content, and to investigate and/or take appropriate action against you in our sole discretion if you violate the Terms and/or otherwise create liability for us or any other person. Such action may include without limitation removing and/or modifying your producer content. We do not allow anyone to record the screen on our platform .</p>
                                            <ol start="8">
                                                <li><strong> NO LEGAL ADVICE; ELECTRONIC COMMUNICATION</strong></li>
                                            </ol>
                                            <p>8.1. No legal advice.&nbsp; The platform does not provide legal advice and cinetie is not a law firm.&nbsp; Part of the Services may involve the making of contracts, and/or other legal relations and although we attempt to make sure our information is accurate and useful, we recommend you consult with a lawyer if you want legal advice.&nbsp; Cinetie does not offer any legal advice, legal opinions, recommendations, referrals, and/or counseling. Cinetie is not involved in agreements between you and other users.</p>
                                            <p><strong>8.2. Local Laws.</strong>&nbsp; The use of the Services may be governed by the laws of different countries or regions, and you agree to abide by such local laws.</p>
                                            <p>8.3. <strong>Electronic Communications.</strong>&nbsp; By using the Services, you agree to receive certain communications in connection with the Services.&nbsp; For contractual purposes, you (i) consent to receive communications from Cinetie in an electronic form; and (ii) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Cinetie provides to you electronically satisfy any legal requirement that such communications would satisfy if it were to be a hardcopy in writing.&nbsp; The foregoing does not affect your non-waivable rights.&nbsp;</p>
                                            <ol start="9">
                                                <li><strong> TERM; TERMINATION</strong></li>
                                            </ol>
                                            <p>Subject to this Section, the Terms will remain in full force and effect while you access and/or use the platform. If you wish to terminate your Account, you may do so by following the instructions through your Account. Cinetie will not have any liability whatsoever to you for any termination of your rights under the Terms, including without limitation for termination of your Account and/or deletion of your User Content. &nbsp;</p>
                                            <ol start="10">
                                                <li><strong> DISCLAIMER OF LIABILITY</strong></li>
                                            </ol>
                                            <p>11.1. Cinetie is under no obligation to become involved in any dispute that you have with other users and/or in any incident that you are party to with other users, or that are affected by and/or otherwise related to the Services.</p>
                                            <p><strong>11.2. Governing Law.</strong>&nbsp; Except to the extent applicable law, if any, provides otherwise, this Agreement and any access to or use of the Services will be governed by the laws of the Republic of Rwanda.</p>
                                            <p><strong>11.3. Reference</strong>. You agree that we may reference you as our customer, and that we may reasonably use, on a royalty-free basis, your trademark and/or logo for such purpose.</p>
                                            <ol start="12">
                                                <li><strong> CONTACT</strong></li>
                                            </ol>
                                            <p>If you would like to request additional information regarding the Terms or for any questions regarding a commercial relationship with Cinetie, please contact us at <a href="mailto:legal@cinetie.com">legal@cinetie.com</a></p>

                                            <div className="text-center">
                                                <button onClick={() => { localStorage.removeItem('usertoken'); this.props.history.push('/signup') }} className="btn btn-primary">Sign Up As A Content Provider</button>
                                            </div>
                                        </div>
                                    )}

                            </div>
                        </div>

                    </div>
                </div>

            </>
        )
    }

}
