import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getViews, getCpVideoEarnings, getEarningsCp, getSales } from '../../actions/barChartActions';
import Charts from './charts'

class Chart extends Component {
    state = {
        Displaydata: null,
        params: []
    }

    componentDidMount() {
        switch (this.props.type) {
            case "views":
                this.setState({ Displaydata: 'views' })
                this.setState({ params: ['city', 'count', 'count'] })
                this.props.getViews(this.props.id)
                break
            case "cpVideoEarnings":
                this.setState({ Displaydata: 'cpVideoEarnings' })
                this.setState({ params: ['period', 'amount', 'amount'] })
                this.props.getCpVideoEarnings(this.props.id)
                break
            case "cpEarnings":
                this.setState({ Displaydata: 'cpEarnings' })
                this.setState({ params: ['period', 'amount', 'amount'] })
                this.props.getEarningsCp(this.props.id)
                break
            case "sales":
                this.setState({ Displaydata: 'Sales' })
                this.setState({ params: ['period', 'amount', 'amount'] })
                this.props.getSales()
                break
            default:
                return
        }

    }

    render() {
        const { barChartData } = this.props.barChart;
        console.log(barChartData);

        return (
            <React.Fragment>
                {barChartData ? (
                    barChartData.length === 0 ? (<div className="mx-auto text-center pt-5 my-5">
                        <div className="spinner-border spinner-border mr-2 text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>) : (<>
                        <Charts payload={barChartData}/>
                        {this.props.barChart.total !== null ? (
                            <div className="bar-chat-cp">
                                {barChartData.city ? 
                                <>
                                <div>{barChartData.city.map((city, index) => {
                                    var index = index + 1 
                                    return (<div className="bar-chat-cp-city" key={index} >
                                        <div className="bar-chat-cp-city-1">{city.city}: </div>
                                        <div className="bar-chat-cp-city-2">{city.views}</div>
                                    </div>)
                                })}</div>
                                <h6>TOTAL: {barChartData.data.sumTotal}</h6>
                            </>
                                 : barChartData.data.sumTotal ? <h6>TOTAL: {barChartData.data.sumTotal.toFixed(2)}</h6> : ''}
                                
                            </div>
                        
                        ) : null}
                        
                        
                    </>
                        )
                ) : (
                        <div className="alert alert-primary alert-dismissible fade show" role="alert">
                            No Data Present
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    )
                }

            </React.Fragment >
        )
    }
}

const mapStateToProps = ({ barChart }) => ({ barChart });
export default connect(mapStateToProps, { getViews, getCpVideoEarnings, getEarningsCp, getSales })(Chart)