import React, { Component } from 'react'
import { connect } from 'react-redux';
import axios from 'axios'
import { NewCard } from '../../components/card'
import { getCPVideos } from '../../actions/cpActions'
import BarChart from '../../components/dashboard/barChart';
import { Loader } from '../../components/video/videoLoader';
import Modal from '../../components/modal'

class mySeries extends Component {

    state = {
        videoStatistics: 'views',
        barChartData: 'views',
        videoEpisodes: [],
        updateVideoId: '',
        updateStatus: null,
        deleteStatus: null,
        videoName: '',
        videoCast: '',
        videoSynopsis: '',
        category: '',
        file: '',
        trailer: '',

        isLoading: false,
        isLoading2: false,

        getSelectedSeason: '1',
        episodeVideoId: '',
        episodeName: '',
        episodeSeason: '1',
        episodeNumber: 1,
        thumb: '',
        video: '',
        synopsis: '',

        uploadTokens: null,
        uploadTrailerTokens: null,
        uploading: null,
        progressBar: 1,
    }

    currentVideo = {}

    componentDidMount() {
        this.props.getCPVideos('serie')
    }

    getUpdateVideo = (id) => {
        this.setState({ updateVideoId: id })
        axios.get(`${process.env.REACT_APP_API_URL}/video/cp/unique?id=${id}`, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}`, 'ip': '155.142.46.148' }
        })
            .then((res) => {
                console.log(res.data.data)
                const { videoName, videoCast, videoSynopsis, category } = res.data.data
                this.setState({ videoName, videoCast, videoSynopsis, category })
            })
            .catch(err => console.log(err.response))
    }

    finishTrailerUpload = () => {
        var trailerData = new FormData();
        trailerData.append('videoId', this.state.updateVideoId);
        trailerData.append('trailer', this.state.uploadTrailerTokens.Key);

        axios({
            url: `${process.env.REACT_APP_API_URL}/trailer/new`, method: 'POST',
            headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}`, 'Content-Type': 'multipart/form-data' },
            data: trailerData
        })
            .then(response => {
                this.setState({ isLoading2: false })
                if (response.status === 201) {
                    alert("Trailer Uploaded Successfully")
                }
                else {
                    this.setState({ uploadStatus: response.message })
                }
            })
            .catch(error => {
                console.log(error)
                this.setState({ isLoading2: false })
                alert(error.response.data.message)
            });
    }

    uploadTrailerToAWS = async () => {
        const file = this.state.trailer;
        await axios.put(this.state.uploadTrailerTokens.url, file, {
            headers: { 'Content-Type': file.type }, onUploadProgress: progressEvent => {
                let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({ progressBar: progress });
            }
        }).then((res) => {
            console.log(res, `UPLOAD COMPLETED`);
            this.setState({ progressBar: 1 });
            this.finishTrailerUpload();
        })
            .catch((err) => {
                this.setState({ isLoading2: false })
                alert(err.response);
                console.log(err.response)
            })
    }

    getTrailerUploadTokens = () => {
        let extension = 'mp4';
        extension = this.state.trailer.name.slice((Math.max(0, this.state.trailer.name.lastIndexOf(".")) || Infinity) + 1);
        axios.get(`${process.env.REACT_APP_API_URL}/trailer/upload-link?extension=${extension}`, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}` }
        })
            .then((res) => {
                this.setState({ uploadTrailerTokens: res.data.data })
                this.uploadTrailerToAWS();
            })
            .catch((err) => {
                console.log(err.response)
            })
    }

    addTrailer = (e) => {
        e.preventDefault()
        this.setState({ isLoading2: true })
        this.getTrailerUploadTokens();
    }

    formChangeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    fileChangeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.files[0] })
    }
    updateVideo = (e) => {
        e.preventDefault()
        const videoUpdate = {}
        const { videoName, videoCast, videoSynopsis, category } = this.state
        if (videoName !== '' && videoName !== this.currentVideo.videoName) { videoUpdate.videoName = videoName }
        if (videoCast !== '' && videoCast !== this.currentVideo.videoCast) { videoUpdate.videoCast = videoCast }
        if (videoSynopsis !== '' && videoSynopsis !== this.currentVideo.videoSynopsis) { videoUpdate.videoSynopsis = videoSynopsis }
        if (category !== '' && category !== this.currentVideo.category) { videoUpdate.category = category }

        axios.put(`${process.env.REACT_APP_API_URL}/video?id=${this.state.updateVideoId}`, videoUpdate, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}` }
        })
            .then((res) => {
                console.log(res.data)
                this.setState({ updateStatus: true })
            })
            .catch(err => {
                console.log(err.response)
                this.setState({ updateStatus: false })
            })
    }

    getEpisodes = (nber, vid) => {
        this.setState({ videoEpisodes: 'loading' })
        axios.get(`${process.env.REACT_APP_API_URL}/series/cp/single/season?s=${nber}&id=${vid}&&limit=40&offset=0`, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}` }
        })
            .then((res) => {
                console.log(res.data.data)
                this.setState({ videoEpisodes: res.data.data })
            })
            .catch((err) => {
                console.log(err.response)
                this.setState({ videoEpisodes: 'empty' })
            })
    }

    deleteEpisode = (id) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/trailer/delete?id=${id}`, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}` }
        })
            .then((res) => {
                alert(res.data.message)
            })
            .catch((err) => console.log(err.response))
    }

    finishUpload = () => {
        var episodeData = new FormData();
        episodeData.append('videoId', this.state.updateVideoId);
        episodeData.append('episodeName', this.state.episodeName);
        episodeData.append('episodeNumber', this.state.episodeNumber);
        episodeData.append('synopsis', this.state.synopsis);
        episodeData.append('season', this.state.episodeSeason);
        episodeData.append('thumb', this.state.uploadTokens['thumb'].Key);
        episodeData.append('video', this.state.uploadTokens['video'].Key);
        axios({
            url: `${process.env.REACT_APP_API_URL}/temp/series/new`, method: 'POST',
            headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}`, 'Content-Type': 'multipart/form-data' },
            data: episodeData
        })
            .then(response => {
                this.setState({ isLoading: false, uploading: null, progressBar: 1 })
                if (response.status === 201) {
                    alert("Episode Uploaded Successfully")
                }
                else {
                    this.setState({ uploadStatus: response.message })
                }
            })
            .catch(error => {
                console.log(error.response)
                this.setState({ isLoading: false, uploading: null, progressBar: 1 })
                alert(error.response.data.message)
            });
    }

    uploadToAWS = async () => {
        const { uploadTokens } = this.state;
        for (let key in uploadTokens) {
            const file = this.state[key];
            console.log(file);
            console.log('UPLOADING', uploadTokens[key])
            if (uploadTokens[key]) {
                this.setState({ uploading: key });
                await axios.put(uploadTokens[key].url, file, {
                    headers: { 'Content-Type': file.type }, onUploadProgress: progressEvent => {
                        let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        this.setState({ progressBar: progress });
                    }
                }).then((res) => {
                    console.log(res, `${key} UPLOAD COMPLETED`);
                    this.setState({ progressBar: 1 });
                })
                    .catch((err) => console.log(err))
            }

        }
        return this.finishUpload();
    }

    getuploadTokens = () => {
        let extension = 'mp4';
        extension = this.state.video.name.slice((Math.max(0, this.state.video.name.lastIndexOf(".")) || Infinity) + 1);
        axios.get(`${process.env.REACT_APP_API_URL}/temp/series/signed-url?extension=${extension}`, {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('cptoken')}` }
        })
            .then((res) => {
                this.setState({ uploadTokens: res.data.data })
                this.uploadToAWS();
            })
            .catch((err) => {
                console.log(err.response)
            })
    }

    addEpisode = (e) => {
        e.preventDefault()
        this.setState({ isLoading: true });
        this.getuploadTokens();
    }


    render() {
        const { cpVideos } = this.props

        return (
            <div>
                <div className="container-fluid text-body">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <span>Content Provider</span>
                        </li>
                        <li className="breadcrumb-item active">My Series</li>
                    </ol>

                    <h4>Series</h4>
                    <hr />
                    <div className="parent">
                        {cpVideos === null ? (
                            <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                No Data Present
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        ) :
                            cpVideos.length === 0
                                ? (<Loader />)
                                : (cpVideos.map(video => (
                                    <NewCard size="3" key={video.id} img={video.videoThumbnailLink} title={video.videoName}>
                                        <Modal
                                            style="btn-primary btn-sm" text="Analytics"
                                            id={video.videoName.replace(/\s/g, '')}
                                            title={video.videoName}
                                            footer={<p>Total Count : {video.videoWatchCount}</p>}>
                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item">
                                                    <a onClick={() => this.setState({ barChartData: 'views' })} className="nav-link active" id="views-tab" data-toggle="tab" href="#views" role="tab" aria-controls="views" aria-selected="true">Views</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a onClick={() => this.setState({ barChartData: 'earnings' })} className="nav-link" id="earnings-tab" data-toggle="tab" href="#earnings" role="tab" aria-controls="earnings" aria-selected="false">Earnings</a>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="views" role="tabpanel" aria-labelledby="views-tab">
                                                    {this.state.barChartData === 'views' ? (
                                                        <BarChart id={video.videoId} type="views" />
                                                    ) : null}
                                                </div>
                                                <div className="tab-pane fade" id="earnings" role="tabpanel" aria-labelledby="earnings-tab">
                                                    {this.state.barChartData === 'earnings' ? (
                                                        <BarChart id={video.videoId} type="cpVideoEarnings" />
                                                    ) : null}
                                                </div>
                                            </div>
                                        </Modal>
                                    </NewCard>
                                )))}
                    </div>

                </div>
            </div>
        )
    }
}
const mapStateToProps = ({ cp }) => {
    return {
        cpVideos: cp.Videos.series,
    }
};
export default connect(mapStateToProps, { getCPVideos })(mySeries)
