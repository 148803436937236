import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { setSignUpStatus } from '../../actions/signUpActions';


class Signup extends React.Component {

	state = {
		error:'',
		// file: '',
		email: '',
		password: '',
		lastName: '',
		firstName: '',
		phoneLine: '',
		nationality: '',
		signupStatus: '',
		cityOfResidence: '',
		confirmPassword: '',
		toggledPassword:false,
		passwordValid: false,
		toggledConfirm:false,
		emailValid: false,
		phoneValid: false,
		isLoading: false,
		terms: false,
	}

	handlerChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value, error: '' })
	}

	signupHandler = (e) => {
		e.preventDefault()
		if (!this.state.terms) { alert("You Need to first Agree To the Terms and Conditions."); return }
		this.setState({ isLoading: true })
		let userData = {
			name: `${this.state.firstName} ${this.state.lastName}`,
			email: this.state.email,
			cityOfResidence: this.state.cityOfResidence,
			nationality: this.state.nationality,
			password: this.state.password,
			phoneLine: this.state.phoneLine
		}

		axios({
			url: `${process.env.REACT_APP_API_URL}/cp/signup`, method: 'POST',
			headers: { 'Content-Type': 'application/json' }, data: userData
		})
			.then(response => {
				if (response.status === 201) {
					this.setState({ signupStatus: "SignUp Success !" })
					this.props.history.push('/verifyEmail');
				}
				else {
					this.setState({ error: response.message })
				}
			})
			.catch(error => {
				error.response.data !== undefined  ? 
				this.setState({ error: error.response.data.message }):
				this.setState({ error: 'Try agian Later' })
			}).finally(ev => 
				this.setState({ isLoading: false })
				);


	}

	handlerToggle = (type,value) => {
		this.setState({ [type]: !value })
	}

	render() {
		const { 
			error,
			terms,
			email,
			password,
			lastName,
			firstName,
			phoneLine,
			isLoading,
			nationality,
			toggledConfirm,
			confirmPassword,
			cityOfResidence,
			toggledPassword,
		 } = this.state;
		return (
				<div className="Auth">
					<h1 className="logo">CINETIE</h1>
					<div className="parent signup">
						<form onSubmit={this.signupHandler}>
								<h1>Welcome</h1>
								<div className="small">
									<div className="input-field">
										<input 
											value={firstName} 
											onChange={this.handlerChange}
											name="firstName"
											placeholder="first name"/>
									</div>
									<div className="input-field">
										<input 
											value={lastName} 
											onChange={this.handlerChange}
											name="lastName"
											placeholder="last name"/>
									</div>
									<div className="input-field">
										<input 
											value={cityOfResidence} 
											onChange={this.handlerChange}
											name="cityOfResidence"
											placeholder="City"/>
									</div>
									<div className="input-field">
										<input 
											value={nationality} 
											onChange={this.handlerChange}
											name="nationality"
											placeholder="nationality"/>
									</div>
								</div>
								
								<div className="coller">
									<PhoneInput
										placeholder="78 3456 789"
										value={phoneLine}
										onChange={e => this.setState({ phoneLine : e })}
										defaultCountry="RW"
										/>
									<br/>
								</div>

								<div className="input-field">
									<input 
										value={email} 
										onChange={this.handlerChange}
										name="email"
										id="email" 
										placeholder="Email"/>
								</div>

								<div className="input-field">
									<input 
										value={password}
										onChange={this.handlerChange}
										id = "password"
										name = "password"
										type = { toggledPassword ? "text" : "password" } 
										placeholder = "Password"
										/>
									{ 
										toggledPassword ? 
										<i onClick={()=> this.handlerToggle("toggledPassword",toggledPassword)} className="fas fa-eye-slash"></i>	:
										<i onClick={()=> this.handlerToggle("toggledPassword",toggledPassword)} className="fas fa-eye"></i>
									}
								</div>
								<div className="input-field">
									<input 
										value={confirmPassword}
										onChange={this.handlerChange}
										id = "confirmPassword"
										name = "confirmPassword"
										type = { toggledConfirm ? "text" : "password" } 
										placeholder="Confirm Password"
										/>
									{ 
										toggledConfirm ? 
										<i onClick={()=> this.handlerToggle("toggledConfirm",toggledConfirm)} className="fas fa-eye-slash"></i>	:
										<i onClick={()=> this.handlerToggle("toggledConfirm",toggledConfirm)} className="fas fa-eye"></i>
									}
								</div>
								{	password !== "" && confirmPassword !== "" && password !== confirmPassword ?
									<small className="match-error">Passwords Don't Match</small> : null
								}
								
								<p className="terms">
										<span> By Signing Up, I Agree To All </span> 
										<span> <Link to='/conditions?type=cp' target="_blank">Terms And Conditions</Link> </span>
										<span>  <input onChange={() => { this.setState({ terms: !terms }) }} defaultChecked={terms} type="checkbox" /> </span>
								</p>

								<p className="error">{ error && <> <span>⚠️</span>  { error } </> }</p>

								<button 
									disabled={ 
										email === "" || !terms || password === "" || confirmPassword === ""
											? true : false }
									>
									{
										isLoading	?
										<i className="spiner fas fa-spinner"></i> :
										<span>Sign Up</span>
									}
								</button>
								<p className="mt-3 text-muted">Already a member ? <Link to="signin"> Sign in here.</Link> </p>
						</form>
						<section></section>		
					</div>
				</div>
		)
	}
}

Signup.propTypes = {
	setSignUpStatus: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
	return {
		signUp: state.signUp,
		plan: state.plan,
	}
};

export default connect(mapStateToProps, { setSignUpStatus })(Signup)
