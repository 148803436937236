import React from 'react';


const ErrorNotFound = () => (
	<>
		<div className="whiteBg hFullSection py-5">
			<div className="container p4-5 py-5">
				<h2 className="text-primary font-weight-bold text-center">CINETIE</h2>
				<div className="card col-lg-10 mx-auto mt-4 text-dark shadow">
					<div className="card-body">
						<div className="row">
							<div className="col-lg-6">
								<img className="img-fluid" src={require('../assets/images/404.png')} alt="" srcSet="" />
							</div>
							<div className="col-lg-6 my-auto">
								<h4>Page Not Found !</h4>
								<h6>Sorry, We couldn't find the page you are Looking For. </h6>
								<div className="pt-3">
									<a className="btn btn-primary" href="/">Take Me Home</a>
								</div>
							</div>
						</div>

					</div>
				</div>

			</div>
		</div>

	</>
);


export default ErrorNotFound;