import React from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";
import 'jquery/dist/jquery'
import 'jquery/dist/jquery.slim'
import 'bootstrap/dist/js/bootstrap.min.js'
import './styles/main.scss';
import 'font-awesome/css/font-awesome.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';
window.$ = $;
window.jQuery = $;


window.$ = $;
window.jQuery = $;

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorkerRegistration.register();

