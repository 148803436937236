import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export function videoLoader() {
    return (
        <div className="mx-auto text-center my-3">
            <div className='linear-background'>
            </div>
        </div>
    )
}


export function Loader(){
    return(
        <>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
            <div style={{background:"#5e5e5e3f",padding:"5px",margin:"1%",borderRadius:"3px"}}>
                <SkeletonTheme color="#e9ecef" highlightColor="#999" style={{background:"grey",}}>
                    <Skeleton circle={true} height={60} width={60} />
                    <br/>
                    <Skeleton width={100} />
                    <br/>
                    <Skeleton width={200} />
                    <br/>
                    <p>
                    <Skeleton count={1}  />
                    </p>
                </SkeletonTheme>
            </div>
        </>
    )
}
